import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决点击当前相同路由的时候控制台报错
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}

const routes = [
  {
    path: '/',
    name: 'Officer',
    component: () => import('@/views/officer')
  },
  {
    path: '/enter',
    name: 'Enter',
    component: () => import('@/views/enter')
  },
  {
    path: '/learning',
    name: 'Learning',
    component: () => import('@/views/learning')
  },
  {
    path: '/teach_curriculum',
    name: 'TeachCurriculum',
    component: () => import('@/views/teach_curriculum')
  },
  {
    path: '/teach_audition',
    name: 'TeachAudition',
    component: () => import('@/views/teach_audition')
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('@/views/service')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/about')
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('@/views/download')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/detail')
  },
  {
    path: '/app_teach_curriculum',
    name: 'AppTeachCurriculum',
    component: () => import('@/views/app_teach_curriculum/index.vue')
  },
  {
    path: '/app_teach_audition',
    name: 'AppTeachAudition',
    component: () => import('@/views/app_teach_audition')
  },
  {
    path: '/app_detail',
    name: 'AppDetail',
    component: () => import('@/views/app_detail')
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('@/views/product')
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('@/views/product')
  },
  {
    path: '/privacy_policy',
    name: 'privacy_policy',
    meta: { title: '向知隐私协议' },
    component: () => import('@/views/policy/xzPrivacyPolicy')
  },
  {
    path: '/agreement',
    name: 'agreement',
    meta: { title: '向知用户注册协议' },
    component: () => import('@/views/policy/xzRegisterPolicy')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/notFound/404')
  },
  {
    path: '*', // 此处需特别注意置于最底部
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
