<template>
  <div class="center">
    <div class="txt1">
      <slot name="txt1"></slot>
    </div>
    <div class="txt2">
      <slot name="txt2"></slot>
    </div>
    <div class="txt3">
      <slot name="txt3"></slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="less">
.center {
  position: relative;
  z-index: 18;
  height: 45px;
  .txt1 {
    z-index: 19;
    font-size: 30px;
    font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
    font-weight: 800;
    color: #f4f4f4;
    line-height: 45px;
    letter-spacing: 1px;
    text-align: center;
  }
  .txt2 {
    font-size: 26px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 37px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: -30px;
  }
  .txt3 {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a1a1a1;
    line-height: 22px;
    text-align: center;
    margin-top: 14px;
  }
}
</style>
