<template>
  <div id="app">
    <MyHeader class="header" v-if="active!=1" />
    <div class="block" v-if="this._isMobile()&&active!=1"></div>
    <router-view />
  </div>
</template>
<script>
export default {
  data () {
    return {
      flag: false,
      active: 0
    }
  },
  watch: {
    '$route.path': {
      handler (to, from) {
        switch (this.$route.name) {
          case 'Officer':
            this.active = -1
            break
          case 'AppTeachCurriculum':
            this.active = 1
            break
          case 'AppTeachAudition':
            this.active = 1
            break
          case 'AppDetail':
            this.active = 1
            break
          case 'agreement' :
            this.active = 1
            break
          case 'privacy_policy' :
            this.active = 1
            break
          default:this.active = 0
        }
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    if (this._isMobile()) {
      if (location.pathname === '/') {
        this.$router.replace('/enter')
      } else {
        this.$router.replace(`${location.pathname}`)
      }
    } else {
      this.$router.replace(`${location.pathname}`)
    }
  },
  mounted () {
    window.addEventListener('offline', function () {
      this.$router.push({
        path: '/404'
      })
    })
  }
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
// 居中
.contain {
  display: flex;
  justify-content: center;
}
.block {
  display: block;
  width: 100%;
  height: 1.32rem;
}
</style>
