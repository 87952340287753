<template>
  <div class="myfooter contain">
    <div class="txt">
      <div class="left">
        <img src="@/assets/code1.png" />
      </div>
      <div class="right">
        <div style="display: flex; justify-content: center">
          <img src="@/assets/logo_bg.png" />
        </div>
        <div class="txt1">
          <p>北京贝湾教育科技有限公司</p>
          <p>地址：北京市朝阳区工体北路甲2号盈科中心A座</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyFooter'
}
</script>

<style lang="less">
.myfooter {
  width: 100%;
  height: 221px;
  border-top: 1px solid;
  border-image: linear-gradient(
      91deg,
      rgba(222, 225, 246, 1),
      rgba(227, 242, 255, 1)
    )
    1 1;
  .txt {
    display: flex;
    .left img {
      width: 138px;
      height: 138px;
      margin: 29px 20px 0 0;
    }
    .right {
      width: 255px;
      height: 221px;
      img {
        width: 146px;
        height: 39px;
        margin-top: 38px;
      }
      .txt1 {
        color: #333333;
        text-align: center;
        font-weight: 400;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        p:nth-child(1) {
          font-size: 16px;
          padding: 24px 0 13px;
        }
        p:nth-child(2) {
          font-size: 12px;
          white-space: nowrap;
          color: #666666;
        }
      }
    }
  }
}
</style>
