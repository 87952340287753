<template>
  <div class="box">
    <div class="guan-slider" @mouseenter="stopPlay" @mouseleave="autoplay">
      <!-- 图片列表 -->
      <ul class="slider-body">
        <li
          class="slider-item"
          :class="{ fade: curIndex === i }"
          v-for="(item, i) in data"
          :key="i"
          @touchend="radioClick(1, i)"
        >
          <img :src="item.imgUrl" alt="" />
        </li>
      </ul>
      <div class="slider-indicator">
        <span
          :class="{ active: curIndex === index }"
          v-for="(item, index) in data"
          @click="radioClick(2, index)"
          :key="index"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slider',
  data () {
    return {
      curIndex: 0,
      timer: null,
      title: '通识力人才培养框架'
    }
  },
  props: {
    // 轮播图的图片列表数据
    data: {
      type: Array,
      default: () => []
    },
    // 控制轮播是否可以自动播放
    isPlay: {
      type: Boolean,
      default: true
    }
  },
  created () {
    this.autoplay()
  },
  destroyed () {
    this.stopPlay()
  },
  methods: {
    // 切换文字
    radioClick (type, index) {
      if (type === 1) {
        if (index === 0) {
          this.curIndex = 1
        } else {
          this.curIndex = 0
        }
      } else {
        this.curIndex = index
      }
    },

    // 播放
    autoplay () {
      // 控制是否可以自动播放
      // if (!this.data.isPlay) return

      this.timer = setInterval(() => {
        this.curIndex++
        if (this.curIndex === 0) {
          this.title = '通识力人才培养框架'
        }
        if (this.curIndex === 1) {
          this.title = '向知特色通识课程体系'
        }
        // 到最后一张图的时候，从头播放（再来一遍）
        if (this.curIndex > this.data.length - 1) {
          this.curIndex = 0
          this.title = '通识力人才培养框架'
        }
      }, 5000)
    },

    // 停止播放
    stopPlay () {
      clearInterval(this.timer)
    }
  }
}
</script>

<style scoped lang='less'>
.box {
  position: absolute;
  top: 1.62rem;
  z-index: 1;
}
.guan-slider {
  width: 100%;
  height: 100%;
  position: relative;
  .slider {
    &-body {
      width: 100%;
      height: 100%;
    }
    // 图片
    &-item {
      display: flex;
      width: 100%;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      transition: opacity 0.5s linear;
      transform: translateY(-0.3rem);
      &.fade {
        opacity: 1;
        z-index: 1;
      }
      img {
        width: 100%;
        height: 3.3rem;
        background-size: contain;
        position: relative;
      }
      .img2 {
        position: absolute;
        top: 26px;
        background-size: contain;
      }
    }
    &-indicator {
      position: absolute;
      left: 0;
      bottom: -1.85rem;
      z-index: 1;
      width: 100%;
      height: 100%;
      text-align: center;
      span {
        display: inline-block;
        width: 0.1rem;
        height: 0.1rem;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        margin: 0 0.1rem;
        cursor: pointer;
        &.active {
          background: #1a4949;
        }
      }
    }
    &-btn {
      width: 44px;
      height: 44px;
      background: rgba(0, 0, 0, 0.2);
      color: #fff;
      border-radius: 50%;
      position: absolute;
      top: 228px;
      z-index: 2;
      text-align: center;
      line-height: 44px;
      opacity: 0;
      transition: all 0.5s;
      &.prev {
        left: 20px;
      }
      &.next {
        right: 20px;
      }
    }
  }
  &:hover {
    .slider-btn {
      opacity: 1;
    }
  }
}
</style>
