<template>
  <div>
    <!-- pc -->
    <div
      class="header pc"
      v-if="!_isMobile()"
      :class="
        $route.path === '/service' ||
        $route.path === '/enter' ||
        $route.path === '/'
          ? 'bg1'
          : $route.path === '/product'
          ? 'bg4'
          : 'bg2'
      "
    >
      <div class="logo">
        <img
          @click="$router.push('/')"
          :src="
            $route.path === '/' ||
            $route.path === '/service' ||
            $route.path === '/product'
              ? require('@/assets/logo.png')
              : require('@/assets/logo_bg.png')
          "
        />
      </div>
      <div class="txt">
        <ul>
          <li
            v-for="(item, index) in list"
            :key="index"
            :class="{ active: active === index }"
            :style="{
              color:
                $route.path === '/' ||
                $route.path === '/service' ||
                $route.path === '/product'
                  ? '#FFFFFF'
                  : '#333333',
            }"
            @click="togo(item.path, index)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
    <!-- 移动端 -->
    <div v-else>
      <div
        class="moblie"
        :class="
          $route.path === '/' || $route.path === '/enter'
            ? 'bg1'
            : $route.path === '/learning'
            ? 'bg3'
            : 'bg2'
        "
      >
        <div class="logo">
          <img
            @click="$router.push('/enter')"
            :src="
              $route.path === '/enter' ||
              $route.path === '/learning'
                ? require('@/assets/logo.png')
                : require('@/assets/logo_bg.png')
            "
          />
        </div>
        <img
          src="@/assets/moblie/icon-01.png"
          v-if="
            $route.path === '/' ||
            $route.path === '/enter' ||
            $route.path === '/learning'
          "
          class="menu-icon"
          @click="popFlag"
        />
        <img
          src="@/assets/moblie/icon-02.png"
          v-else
          class="menu-icon"
          @click="popFlag"
        />
      </div>
      <div class="pop-box" v-if="flag">
        <div
          v-for="(item, index) in list2"
          :key="index"
          :style="active == index ? 'color:#619878' : ''"
          @click="togo(item.path, index)"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="mask" v-if="flag" @click="close"></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      active: -1,
      list: [
        { id: 0, title: '走进贝湾', path: '/enter' },
        { id: 1, title: '学术委员会', path: '/learning' },
        { id: 2, title: '内容体系', path: '/teach_curriculum' },
        { id: 3, title: '产品体系', path: '/product' },
        { id: 4, title: '关于我们', path: '/about' },
        { id: 5, title: '下载APP', path: '/download' }
      ],
      list2: [
        { id: 0, title: '走进贝湾', path: '/enter' },
        { id: 1, title: '学术委员会', path: '/learning' },
        { id: 2, title: '内容体系', path: '/teach_curriculum' },
        { id: 3, title: '产品体系', path: '/product' },
        { id: 4, title: '关于我们', path: '/about' }
      ],
      flag: false
    }
  },
  watch: {
    $route (to, from) {
      switch (this.$route.path) {
        case '/':
          this.active = -1
          break
        case '/learning':
          this.active = 1
          break
        case '/enter':
          this.active = 0
          break
        case '/teach_curriculum':
          this.active = 2
          break
        case '/teach_audition':
          this.active = 2
          break
        case '/product':
          this.active = 3
          break
        case '/service':
          this.active = 3
          break
        case '/about':
          this.active = 4
          break
        case '/download':
          this.active = 5
          break
      }
    }
  },
  methods: {
    togo (path, i) {
      this.active = i
      this.$router.push(path)
      this.flag = false
    },
    popFlag () {
      this.flag = !this.flag
    },
    close () {
      this.flag = false
    }
  }
}
</script>
<style scoped lang="less">
.moblie {
  height: 1.32rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  justify-content: space-between !important;
  padding: 0 0.4rem;
  z-index: 2;
  .logo {
    display: flex;
    align-items: center;
    z-index: 2;
    width: 211px;
    height: 73px;
    img {
      width: 110px;
      height: 30px;
    }
  }
  .menu-icon {
    display: block;
    width: 0.4rem;
    height: 0.38rem;
    z-index: 3;
  }
}
.bg1 {
  background: #1b4a49;
}
.bg2 {
  background: #fff;
}
.bg3 {
  background: url("~@/assets/moblie/bg_04.png") no-repeat center / cover;
  z-index: 1;
}
.bg4 {
  background: #2b4a4a;
}
.pop-box {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 2.14rem;
  background: #fff;
  position: fixed;
  top: 1.2rem;
  right: 0.4rem;
  z-index: 999;
  color: #333;
  padding: 0.3rem 0;
  div {
    padding: 0.18rem 0;
    font-size: 0.28rem;
  }
}
.mask {
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 80;
}
.pc {
  z-index: 1;
  height: 81px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .logo {
    display: flex;
    align-items: center;
    z-index: 2;
    width: 211px;
    height: 73px;
    img {
      width: 146px;
      height: 39px;
      top: 20px;
    }
  }
  .txt {
    z-index: 2;
    ul {
      display: flex;
      li {
        flex: 1;
        list-style: none;
        width: 94px;
        height: 81px;
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        text-align: center;
        line-height: 81px;
        margin: 0 11.5px;
        &:nth-child(1) {
          margin-left: 0;
        }
        &:nth-child(6) {
          margin-right: 0;
        }
        &.active {
          position: relative;
          &::after {
            content: "";
            position: absolute;
            height: 5px;
            width: calc(100% - 60px);
            background: #5fa381;
            bottom: 14px;
            left: 30px;
            border-radius: 2px;
          }
        }
      }
    }
  }
}
</style>
