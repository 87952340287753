<template>
  <div class="moblie-footer">
    <div class="foot-card">
      <p class="foot-t">联系我们</p>
      <div class="foot-box">
        <div>
          <img src="@/assets/code1.png" />
          <span>扫码关注公众号</span>
        </div>
        <div>
          <img src="@/assets/code4.png" />
          <span>企业微信号</span>
        </div>
      </div>
      <div class="address">
        <p class="address-t">北京贝湾教育科技有限公司</p>
        <p class="address-b">地址：北京市朝阳区工体北路甲2号盈科中心A座</p>
      </div>
    </div>
    <div class="foot">
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43019002001604"
        >湘公网安备 43019002001604号</a
      >
      <a target="_blank" href="https://beian.miit.gov.cn"
        >湘ICP备 18024560号-1</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyFooter'
}
</script>

<style lang="less">
.moblie-footer {
  z-index: 2;
  padding: 0.4rem;
  background: #fff;
  .foot-card {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.4rem;
    background: #fff;
    border-radius: 10px;
    .foot-t {
      font-size: 0.4rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-bottom: 0.2rem;
    }
    .foot-box {
      display: flex;
      div {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 2.68rem;
        height: 2.68rem;
      }
      div:first-child img {
        margin-right: 0.4rem;
      }
      div:first-child span {
        margin-right: 0.4rem;
      }
      span {
        font-size: 0.28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .address {
    margin: 0.5rem 0.2rem 0 0.2rem;
    .address-t {
      border-left: 0.1rem solid #1e4b4b;
      font-size: 0.32rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      height: 0.33rem;
      line-height: 0.33rem;
      padding-left: 0.1rem;
    }
    .address-b {
      font-size: 0.26rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-top: 0.18rem;
      white-space: nowrap;
    }
  }
  .foot {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.6rem 0 0.1rem;
    a {
      font-size: 0.2rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #c5c5c5;
      text-decoration: none;
    }
    a:first-child {
      margin-right: 0.2rem;
    }
  }
}
</style>
