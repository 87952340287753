<template>
  <div class="box" style="height: 388px">
    <div class="center0 contain">
      <p>
        {{ this.title }}
      </p>
    </div>
    <div class="guan-slider" @mouseenter="stopPlay" @mouseleave="autoplay">
      <!-- 图片列表 -->
      <ul class="slider-body">
        <li
          class="slider-item"
          :class="{ fade: curIndex === i }"
          v-for="(item, i) in data"
          :key="i"
        >
          <img :src="item.imgUrl" alt="" />
         <div class="contain">
            <img :src="item.imgUrl2" alt="" class="img2"/>
         </div>
        </li>
      </ul>
      <div class="slider-indicator">
        <span
          :class="{ active: curIndex === index }"
          v-for="(item, index) in data"
          @click="radioClick(index)"
          :key="index"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slider',
  data () {
    return {
      curIndex: 0,
      timer: null,
      title: '『三名』原则'
    }
  },
  props: {
    // 轮播图的图片列表数据
    data: {
      type: Array,
      default: () => []
    },
    // 控制轮播是否可以自动播放
    isPlay: {
      type: Boolean,
      default: true
    }
  },
  created () {
    this.autoplay()
  },
  destroyed () {
    this.stopPlay()
  },
  methods: {
    // 切换文字
    radioClick (index) {
      this.curIndex = index
      if (index === 0) {
        this.title = '『三名』原则'
      }
      if (index === 1) {
        this.title = '『万花筒』课程体系'
      }
    },

    // 播放
    autoplay  () {
      // 控制是否可以自动播放
      // if (!this.data.isPlay) return

      this.timer = setInterval(() => {
        this.curIndex++
        if (this.curIndex === 0) {
          this.title = '『三名』原则'
        }
        if (this.curIndex === 1) {
          this.title = '『万花筒』课程体系'
        }
        // 到最后一张图的时候，从头播放（再来一遍）
        if (this.curIndex > this.data.length - 1) {
          this.curIndex = 0
          this.title = '『三名』原则'
        }
      }, 5000)
    },

    // 停止播放
    stopPlay () {
      clearInterval(this.timer)
    }
  }
}
</script>

<style scoped lang='less'>
.center0 {
  height: 60px;
  background: #367775;
  p {
    height: 60px;
    font-size: 26px;
    // font-family: cursive;
    font-weight: 600;
    color: #ffffff;
    line-height: 60px;
    letter-spacing: 1px;
  }
}
.guan-slider {
  width: 100%;
  height: 388px;
  min-width: 300px;
  min-height: 150px;
  position: relative;
  .slider {
    &-body {
      width: 100%;
      height: 388px;
    }
    // 图片
    &-item {
      width: 100%;
      height: 388px;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 0;
      // 图片默认不显示
      opacity: 0;
      transition: opacity 0.5s linear;
      &.fade {
        // 图片显示
        opacity: 1;
        z-index: 1;
      }
      img {
        width: 100%;
        height: 388px;
        background-size: contain;
        position: relative;
      }
      .img2{
        position: absolute;
        top: 26px;
        width: 1372px;
        height: 307px;
        background-size: contain;
      }
    }
    &-indicator {
      position: absolute;
      left: 0;
      bottom: 20px;
      z-index: 2;
      width: 100%;
      text-align: center;
      span {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        cursor: pointer;
        ~ span {
          margin-left: 12px;
        }
        &.active {
          background: #1a4949;
        }
      }
    }
    &-btn {
      width: 44px;
      height: 44px;
      background: rgba(0, 0, 0, 0.2);
      color: #fff;
      border-radius: 50%;
      position: absolute;
      top: 228px;
      z-index: 2;
      text-align: center;
      line-height: 44px;
      opacity: 0;
      transition: all 0.5s;
      &.prev {
        left: 20px;
      }
      &.next {
        right: 20px;
      }
    }
  }
  &:hover {
    .slider-btn {
      opacity: 1;
    }
  }
}
</style>
