import Vue from 'vue'
import App from './App.vue'
import router from './router'

import MyFooter from './components/MyFooter.vue'
import MyHeader from './components/MyHeader.vue'
import CenterSlot from './components/CenterSlot.vue'
import Slider from './components/slider'
import MyMoblieFooter from './components/MyMoblieFooter.vue'
import MoblieSlider from './components/moblieSlider.vue'
// import VConsole from 'vconsole'
// Vue.prototype.$vconsole = VConsole
// const vConsole = new VConsole()
// Vue.use(vConsole)
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)

Vue.component('MyFooter', MyFooter)
Vue.component('MyHeader', MyHeader)
Vue.component('CenterSlot', CenterSlot)
Vue.component('Slider', Slider)
Vue.component('MyMoblieFooter', MyMoblieFooter)
Vue.component('MoblieSlider', MoblieSlider)

function _isMobile () {
  const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
  // return false
}
Vue.prototype._isMobile = _isMobile
// 切换路由返回页面顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

// router.beforeEach((to, from, next) => {
//   if(_isMobile){
//     if(to.path === '/'){
//       next('enter');
//     }
//   }else {
//     next('officer');
//   }
// });

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
